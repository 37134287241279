<template>
	<div
		class="btn offer"
		@click="selectThisBuyInOffer($event)"
		:class=" allBuyInOffersNotSelected ? '': buyInOfferSelected ? '' : 'buy-in-offer-not-selected'"
		:id="`offer-${buyInOffer.id}`"
	>
	<span v-if="buyInOfferSelected" @click="closeBuyInOffer()" class="text-font-15">&#10006;</span>
	
		<h1>{{ buyInOffer.name }}</h1>
		<div class="bold-text">
			<p class="flex-column">
				<span>{{ buyInCashString() }} {{ languageStrings.cashBuys }}</span>
				<span
					>{{
						inGameFormatAUasCurrency(
							buyInOffer.creditRegularAUPerCashCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
					{{ languageStrings.regularCredit }}</span
				>
				<span
					>{{ languageStrings.and }}
					{{
						inGameFormatAUasCurrency(
							this.buyInOffer.creditPromoAUPerCashCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
					{{ languageStrings.promo }}</span
				>
			</p>
			<p>
				<small>
					<span
						>{{ languageStrings.min }}:
						{{ systemFormatCurrency(buyInOffer.minimumCOC, systemCurrencyTool.displayType.minorOrFull) }} |
						{{ languageStrings.max }}:
						{{
							systemFormatCurrency(buyInOffer.maximumCOC, systemCurrencyTool.displayType.minorOrFull)
						}}</span
					>
				</small>
			</p>
		</div>
		<CommitBuyInOffer
			:buyInOfferSelected="buyInOfferSelected"
			:playerState="playerState"
			:buyInOffer="buyInOffer"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
			:webPayOptions="webPayOptions"
			:isMobile="isMobile"
			:restrictedNeedsMoreInfo="restrictedNeedsMoreInfo"
		/>
	</div>
</template>

<script>
import CurrencyTool from "@/dependencies/currencyTool";
import CommitBuyInOffer from "@/components/CommitBuyInOffer.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "BuyInOffer",
	inheritAttrs: false,
	props: {
		playerState: Object,
		buyInOffer: Object,
		// inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		webPayOptions: Object,
		inGameCurrencyInfo: Object,
		casinoList: Array,
		isMobile: Boolean,
		restrictedNeedsMoreInfo: Boolean,
	},
	components: {
		CommitBuyInOffer,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			cashOutCurrency: this.buyInOffer.creditPromoAUPerCashCOC,
			systemCurrencyInfo: this.buyInOffer.cashOutCurrency,
			buyInOfferSelected: false,
			inGameCurrencyTool: new CurrencyTool(this.inGameCurrencyInfo, this.localeString),
			buyInOfferClosed: false,
			allBuyInOffersNotSelected: true,
		};
	},
	created() {
		this.eventBus.on("nuveiCheckoutResult", () => {
			this.buyInOfferSelected = false;
		});
		this.eventBus.on("deselectBuyInOffer", (buyInId) => {
			// Deselects all buy-ins other than the id specified in the payload for this event.
			// Pass null in the payload for this event to deselect all buy-in offers.
			if (buyInId != this.buyInOffer.id) {
				this.allBuyInOffersNotSelected = false;
				this.buyInOfferSelected = false;
				var currentOffer = document.getElementById(`offer-${this.buyInOffer.id}`);
				currentOffer.classList.add("buy-in-offer-not-selected");
				
			}
			 else {
				this.allBuyInOffersNotSelected = false;
				this.buyInOfferSelected = true;
			}

			if(this.buyInOfferClosed) {
				this.buyInOfferSelected = false;
				this.allBuyInOffersNotSelected = true;
			}

		});
		this.eventBus.on("allBuyInOffersNotSelected", (buyInId) => {
			if(buyInId != this.buyInOffer.id){
				var currentOffer = document.getElementById(`offer-${this.buyInOffer.id}`);
				currentOffer.classList.contains("buy-in-offer-not-selected") ? currentOffer.classList.remove("buy-in-offer-not-selected") : '';
			}
			
		});

		onBeforeUnmount(() => {
			this.eventBus.off("nuveiCheckoutResult");
			this.eventBus.off("deselectBuyInOffer");
		});

	},
	methods: {
		selectThisBuyInOffer() {

			if(this.buyInOfferClosed) {
				this.buyInOfferSelected = false;
				this.buyInOfferClosed = false;
				return;
			}
			this.eventBus.emit("deselectBuyInOffer", this.buyInOffer.id);
			this.buyInOfferClosed = false;

		},
		systemFormatCurrency(valueCOC, display = {}) {
			return this.systemCurrencyTool.formatCurrency(valueCOC, display);
		},
		inGameFormatAUasCurrency(valueCOC, display = {}) {
			return this.inGameCurrencyTool.formatFromAU(valueCOC, display);
		},
		buyInCashString() {
			const compareFactor = this.systemCurrencyTool.currencyInfo.minorDigits < 1 ? 100 : 1;
			return this.systemCurrencyTool.formatCurrency(
				compareFactor,
				this.systemCurrencyTool.displayType.minorWholeOrFull
			);
		},
		closeBuyInOffer() {
			this.buyInOfferClosed = true;
			this.allBuyInOffersNotSelected = true;
			this.eventBus.emit("allBuyInOffersNotSelected", this.buyInOffer.id);
		
		},	
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
small {
	display: block;
	text-align: center;
}

h1 {
	line-height: 1.125em;
}

.flex-column {
	display: flex;
	flex-flow: column nowrap;
}

p.flex-column {
	text-align: center;
}

.btn:hover {
	transform: none;
}

.offer {
	margin-bottom: 15px;
}

.buy-in-offer-not-selected {
		opacity: 0.2;
		max-height: 210px;
}

.arrow {
	border: solid black;
	border-width: 0 3px 3px 0;
	opacity: 100% !important;
}

.text-font-15 {
	font-size: 15px;
}

@media (max-width: 768px) {
	.buyInOfferSelected {
		color: #fff;	
	}
}
</style>
